html { font-family: 'Roboto', sans-serif; }


/*body {*/
  /*margin: 0;*/
  /*padding: 0;*/
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
    /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
/*}*/
/* 
a {
    text-decoration: none !important;
}*/

.color_black {
    color: #000 !important;
}
.color_black54 {
    color: #545454 !important;
}
.hell_blau {
    color: #81D5E6 !important;
}
.dunkel_blau {
    color: #0D47A1 !important;
}
.petrol {
    color: #1E939C !important;
}
.gruen {
    color: #4caf50 !important;
}
.dunkel_gelb {
    color: #CDDC39 !important;
}
.rot {
    color: #B71C1C !important;
}
.hell_rot {
    color: #EB6565 !important;
}


.lila {
    color: #9C27B0 !important;
} 