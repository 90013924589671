
/* .progressClass {
} */
.info_charge {
    position: relative;
}
/* .chipclass {

} */

.info_charge {
    color: #ffd600 !important;
    font-weight: 900;
}
.warning_charge {
    color: #ff6d00 !important;
    font-weight: 900;
}
.critical_charge {
    color: #B71C1C !important;
    font-weight: 900;
}

.charge_icon {
    /* margin-top: 2px; */
    padding-top: 0px;
    color: #777 !important;
    height: 14px !important;
    width: 14px !important;
}

.charge_icon_info {
    /* margin-top: 2px; */
    color: #ffd600 !important;
    padding-top: 0px;
    height: 14px !important;
    width: 14px !important;
}

.charge_icon_warn {
    color: #ff6d00 !important;
    padding-top: 0px;
    height: 14px !important;
    width: 14px !important;
}

.charge_icon_critical {
    color: #B71C1C !important;
    padding-top: 0px;
    height: 14px !important;
    width: 14px !important;
}

.scale {
  padding: 8px;
}

.scale:hover {
    background-color: #f6f6f6;
    cursor: pointer;
}

/* .eventPoint {

} */
.eventPoint:hover {
    background-color: #f6f6f6;
    cursor: pointer;
}


.header_chart {
    padding: 8px;
    user-select: none; /* darf nicht selektiert werden*/
}


.chart_back_button {
    margin-right: 16px !important;
    margin-bottom: 16px !important;
}

.scale_header {
    padding-bottom: 16px !important;
}

/* .scale_grid_week {
    /* padding: 4px; */
    /* border: 1px solid #DDD; */
/* }  */


.scale_grid_week {
    padding-left: 4px;
    padding-right: 4px;
    margin-bottom: 8px;;
    margin-left: 8px;;
    border: 1px solid #DDD;
    border-radius: 5px;
}
.scale_grid_padding4 {
    padding: 4px;
}
.scale_border_left {    
    border-left: 1px solid #DDD;
}
.scale_border_right {
    border-right: 1px solid #DDD;
}
.scale_border_top {
    border-top: 1px solid #DDD;
}
.scale_border_bottom {
    border-bottom: 1px solid #DDD;
}

.scale_grid {
    padding: 8px !important;
}

.remove_bottom_margin {
    margin-bottom: 0px !important;
    line-height: 1.1 !important;
}


.svg_chart {
    background-color:#FFF;
    height: 100%; /*100%;*/
    width: 100%;
    /*margin-right: -24px; !* hack!! entfernen *!*/
}

.custom-tooltip {
    padding: 24px !important;
    margin-right: 24px !important;

}


/* CHART */
.zoom {
    cursor: move;
    fill: none;
    pointer-events: all;
}

.grid line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
}
.grid path {
    stroke-width: 0;
}
.grid {
    padding: 0px;
}

[padding] {
    padding: 0px !important;
}

.sensor-icon {
    font-size:16px;
    height: 16px;
    width: 16px;
    align-self: center;
}
.sensor-value {
    font-size: 1.8em;
    font-weight: 600;
    margin: -8px 0px;
    padding: 0px 4px 0px 4px;
    align-self: center;

}
.sensor-unit {
    font-size: 1.2em;
    font-weight: 300;
    align-self: center;
}
.sensor-small-header {
    font-size: 0.7em;
    color: #666;
}

.tempAxis >text, .tempAxis > g >text {
    fill: #0D47A1 !important;;
}
.humAxis > text, .humAxis  > g >text{
    fill: #9C27B0 !important;
}
.weightAxis > text, .weightAxis  > g >text{
    fill: #B71C1C !important;
}

.settings-col {
    border-left: 1px solid #ccc;
}
.item-md {
    padding-left: 0px;
}

.item.item-md .checkbox-md {
    margin: 8px 0px 9px 4px
}

#brush_g > g >rect.selection {
    fill: #000066 !important;
    fill-opacity: 0.1 !important;
}
#brush_g > g >rect.handle--e {
    fill: #0D47A1 !important;
    width: 2px !important;
}
#brush_g > g >rect.handle--w {
    fill: #0D47A1 !important;
    width: 2px !important;
}


@media (min-width: 768px) {

    .outer_grid{
        min-height: 1000px;
    }
}