.scale {
  padding: 8px;
  line-height: 1;
}
.value_icon {
    padding: 4px;
}
.sensor_value {
    /*font-size: 1.8em;*/
    /*font-weight: 600;*/
    margin: -8px 0px !important;
}